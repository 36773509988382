export let l_en = {
  'elite_online': 'Elite Online',
  'home': 'Home',
  'about_us': 'About Us',
  'our_services': 'Our Services',
  'testimonial': 'Testimonials',
  'contact': 'Contact',
  'subscribe': 'Subscribe',
  'text1': 'Elite Online- Your time, your income!',
  'text2': 'VALUE YOUR TIME: INVEST IT WITH ELITE ONLINE, A SMART WAY TO INCREASE YOUR INCOME.',
  'text3': 'About us',
  'text4': 'The Elite Online company operates as part of a merger with Elite Taxi Cab. Capturing the need of our community and given the growth in demand for service, the idea ​​Elite Online, "The APP" was born in which will support Elite Taxi Cab by uniting the two concepts, working faster and providing better service to our customers. In order for us to provide you with a quality service, here at Elite Online our operators will answer each of your calls 24/7. ',
  'text5': 'Our Services',
  'text6': '',
  'text7': 'Join Elite Online',
  'text8': 'DRIVE YOUR OWN CAR. Your car is at your command, always. Use it to earn money! With Elite Online you choose your own schedule. Whether it be full-time or part-time, you will earn money as you travel around town and connect with new people at the comfort of your own vehicle.',
  'text9': 'REQUIREMENTS',
  'text10': 'Your vehicle must be 10 years or newer',
  'text11': 'Excellent mechanical conditions',
  'text12': '4-door cars, impeccable upholstery and A / C, clean body',
  'text13': 'No scratches or bumps',
  'text14': 'Drive An Elite Taxi',
  'text15': '',
  'text16': 'DRIVER REQUIREMENTS',
  'text17': 'Good presentation',
  'text18': 'Current license',
  'text19': 'Driving record for the last 3 years',
  'text20': 'NO more than 6 points on the license',
  'text21': 'NO DUI',
  'text22': 'NO criminal record',
  'text23': 'NO accidents',
  'text24': 'Willingness to work',
  'text25': 'Insurance required by law',
  'text26': 'Ask For A Ride',
  'text27': 'Request a Ride. Download the application (clicking will start the application download)',
  'text28': 'Why Elite Online? We are a team that cares about the details. You deserve a company with local service. The Elite Online app offers you a safer and easier way to travel.',
  'text29': 'Contact',
  'text30': 'At Eline Online we want to know your opinion! The only way to know what our service is like is through your feedback. We appreciate you sending us a message about your latest experience with us.',
  'text31': 'Give US Your Feedback',
  'text32': '',
  'text33': 'Ronald Watson Jr',
  'text34': 'Fast friendly service when looking to getting around the local Phoenix area. Good job guys and girls keep up the good work thank you.',
  'text35': 'Michael Karp',
  'text36': 'The driver was very helpful, we had too much food so we had to take a cab because I had too much to carry on the bus, but it was well worth it, the cab driver was very helpful and clean smelling car very grateful for his service.',
  'text37': 'Kolette Fife',
  'text38': 'The driver got there in 15 minutes. I was impressed.',
  'text39': 'Joyce Foster',
  'text40': 'Courteous, and very respectful. Their drivers are a cut above the rest!!!',
  'text41': 'Nono Kube',
  'text42': 'Positive: Professionalism, Punctuality, Quality, Value. Thank God there are still reliable and trustworthy places like Elite! At 4am, Ubers get scarce and quite frankly, their reliability has been a problem of recent. Having a somewhat of emergency situation and needing to get my son from Glendale to Phoenix, this company, not only answered the phone (a human) which in itself now a days, is almost an act of God, but a very nice dispatcher took the info, there was a small language barrier but between the two of us, she got the address correct, when she said 20 minutes, her driver was there is 20 minutes, and all worked out perfectly! I used again the next morning, today, and will continue to do as much as possible. One more thing, the prices are more than fair so rumors taxi are higher was not my experience at all with Elite. Thank you so much and we will be seeing you again!!',
  'text43': 'Close',
  'text44': 'Download Our App',
  'text45': 'Get Information',
  'text46': 'Send Message',
  'text47': 'Useful Links',
  'text48': 'Our Services',
  'text49': 'Subscribe to our Newsletter.',
  'text50': 'Subscribe',
  'text51': 'Your Name',
  'text52': 'Your Email',
  'text53': 'Subject',
  'text54': 'Message',
  'text55': 'Sending',
  'text56': 'Thank you for your feedback. We appreciate and take your recommendation into account! Sincerely, Elite Online.',
  'text57': 'Elite Online supporting the growth of Elite Taxi Cab.  Together we will travel our Valley, with a single objective: To provide a better service.',
  'text58':'Powered by IT Sonora Solutions',
  'text59':'Error sending mail.',
  'text60':'Error when subscribing to the newsletter.',
  'text61':`THANK YOU FOR SUBSCRIBING!
  We will inform you about new up to date blogs and news.
  From all of us at ELITE ONLINE,  thank you for your interest!`,
  'text62':'Invalid Emal address',
  'text63':'Register',
  'text64':'Submit',
  'text65':'Register as an Independent Contractor',
  'text66':'Register as a Driver',
  'ri_first_name':'First Name',
  'ri_last_name':'Last Name',
  'ri_city':'City',
  'ri_street':'Street',
  'ri_number':'Street number',
  'ri_nsew':'NSEW',
  'ri_zipcode':'Zip Code',
  'ri_email':'Email',
  'ri_phone':'Phone',
  'ri_howfindus':'How Find Us?',
  'ri_vehiclebrand':'Brand',
  'ri_vehiclemodel':'Model',
  'ri_vehicleinsurancedata':'Insurance Data',
  'ri_vehicleemissiontest':'Emission Test',
  'ri_vehicletitle':'Title',
  'ri_vehicleplates':'Plates',
  'ri_workexperience':'Work Experience',
  'ri_userinformation':'User Information',
  'ri_vehicleinformation':'Vehicle Information',
  'ri_submit':'Submit',
  'ri_success':'Your message has been successfully sent! We will contact you shortly.',
  

  
}

export let l_es = {
  'elite_online': 'Elite Online',
  'home': 'Inicio',
  'about_us': 'Sobre Nosotros',
  'our_services': 'Nuestros Servicios',
  'testimonial': 'Testimonial',
  'contact': 'Contacto',
  'subscribe': 'subscribe',
  'text1': '¡Elite Online- ¡Tu tiempo, tu ingreso!',
  'text2': 'DALE VALOR A TU TIEMPO: INVIERTELO CON ELITE ONLINE, UNA MANERA INTELIGENTE DE AUMENTAR TUS INGRESOS.',
  'text3': 'Sobre Nosotros',
  'text4': 'La compañía de Elite Online funciona como parte de una fusión con Elite Taxi Cab. Captando la necesidad de nuestra comunidad y ante el crecimiento en el servicio nace la idea de Elite Online “La APP” que apoyara a Elite Taxi Cab uniendo los dos conceptos, se trabajara con más rapidez y mejor servicio a nuestros clientes. En Elite Online contamos servicio 24/7, nuestras operadoras atienden cada una de sus llamadas con el fin de brindarle un servicio de calidad.',
  'text5': 'Nuestros Servicios',
  'text6': '',
  'text7': 'Únete a Elite Online',
  'text8': 'MANEJA TU PROPIO AUTO. Tu auto está a tus ordenes, siempre. ¡Hazlo ganar dinero! Con Elite Online tú eliges tu horario. Ya sea a tiempo completo o en horas extras, gana dinero mientras viajas por la ciudad y conectas con gente nueva desde la comodidad de tu auto.',
  'text9': 'REQUISITOS',
  'text10': 'Carros 10 años de antigüedad',
  'text11': 'Excelentes condiciones mecánicas',
  'text12': 'Autos de 4 puertas, tapicería y A/C impecables, carrocería limpia',
  'text13': 'Sin rayas o golpes',
  'text14': 'Maneja Un Elite Taxi',
  'text15': '',
  'text16': 'REQUISITOS DEL CHOFER',
  'text17': 'Buena presentación',
  'text18': 'Licencia vigente',
  'text19': 'Récord de manejo últimos 3 años',
  'text20': 'No más de 6 puntos en la licencia',
  'text21': 'No Dui’s',
  'text22': 'Buen récord criminal',
  'text23': 'No accidentes',
  'text24': 'Ganas de trabajar',
  'text25': 'Aseguranza requerida por la ley',
  'text26': 'Pide Un Servicio',
  'text27': 'Solicita un Taxi. Descarga la aplicación (al dar click empezara la descarga de la aplicación)',
  'text28': '¿Por qué Elite Online?  Somos un equipo que se preocupa por los detalles. Usted merece una empresa con servicio local. La aplicación Elite Online le ofrece una forma más segura y fácil de viajar.',
  'text29': 'Contacto',
  'text30': 'Buzón de sugerencias. ¡En Elite Online queremos saber tu opinión! La única forma de saber cómo es nuestro servicio es a través de tu retroalimentación. Agradecemos nos mande un mensaje sobre su último servicio.',
  'text31': 'Danos tu opinion',
  'text32': '',
  'text33': 'Ronald Watson Jr',
  'text34': 'Servicio rápido y amigable si estás buscando moverte en el área de Phoenix. Buen trabajo chicos y chicas y sigan con el buen servicio.',
  'text35': 'Michael Karp',
  'text36': 'El conductor fue muy de mucha ayuda, fuimos al mandado y compramos demasiado, así que tuvimos que tomar un taxi porque era demasiado para irnos en el autobús, pero valió la pena, el conductor del taxi fue muy servicial y el auto olía a limpio muy agradecido con su  servicio.',
  'text37': 'Kolette Fife',
  'text38': 'El conductor llegó en 15 minutos. Me quede impresionada.',
  'text39': 'Joyce Foster',
  'text40': 'Cortes y muy respetuoso.  ¡Sus conductores están por encima del resto!',
  'text41': 'Nono Kube',
  'text42': 'Profesional, Puntualidad, Calidad, Valor ¡Gracias a Dios, todavía hay lugares confiables como Elite!  A las 4 de la mañana, los Ubers escasean y, francamente, su fiabilidad ha sido un problema reciente.  Tenía una situación de emergencia, tenía que llevar a mi hijo de Glendale a Phoenix, esta compañía, no solo respondió el teléfono (un humano) que en sí mismo ahora es casi un acto de Dios, sino que un despachador muy amable tomó la  info, había una pequeña barrera del idioma, pero entre nosotros dos, ella obtuvo la dirección correcta, cuando dijo 20 minutos, su conductor estaba allí, son 20 minutos, ¡y todo funcionó perfectamente!  Lo usé de nuevo a la mañana siguiente, hoy, y continuaré usándolo tanto como sea posible.  Una cosa más, los precios son más que justos por lo que los rumores de taxi son más altos no fue mi experiencia en absoluto con Elite.  Muchas gracias y nos veremos de nuevo !!',
  'text43': 'Cerrar',
  'text44': 'Descarga Nuestra App',
  'text45': 'Obtener Información',
  'text46': 'Enviar Mensaje',
  'text47': 'Enlaces Útiles',
  'text48': 'Nuestros Servicios',
  'text49': 'Suscríbase a nuestro boletín.',
  'text50': 'Suscribir',
  'text51': 'Nombre',
  'text52': 'Email',
  'text53': 'Asunto',
  'text54': 'Mensaje',
  'text55': 'Enviando',
  'text56': '¡Muchas gracias por su comentario! ¡Agradecemos y tomamos en cuenta su recomendación! Atentamente, Elite Online.',
  'text57': 'Muy pronto Elite Online apoyando el crecimiento de Elite Taxi Cab. Juntos recorreremos nuestro Valle, con un solo objetivo: Dar un mejor servicio.',
  'text58':'Desarrollado por Sistemas y Soluciones Tecnológicas de Sonora',
  'text59':'Error al envia el correo.',
  'text60':'Error al suscribirse al boletín.',
  'text61':`¡GRACIAS POR SUSCRIBIRSE A NUESTRO BOLETÍN!

  Le informaremos sobre nuevas notas en nuestro blog y noticias.
  
  ¡Todo el equipo de ELITE ONLINE le agradece su interés!`,
  'text62':'Direccion de correo electronico no valido',
  'text63':'Registrese',
  'text64':'Enviar',
  'text65':'Regístrese como contratista independiente',
  'text66':'Registrarse como conductor',
  'ri_first_name':'Nombre',
  'ri_last_name':'Apellido',
  'ri_city':'Ciudad',
  'ri_street':'Calle',
  'ri_number':'Numero de Calle',
  'ri_nsew':'NSEW',
  'ri_zipcode':'Codigo Postal',
  'ri_email':'Correo Electronico',
  'ri_phone':'Teléfono Mobil',
  'ri_howfindus':'¿Como nos encontro?',
  'ri_vehiclebrand':'Marca',
  'ri_vehiclemodel':'Modelo',
  'ri_vehicleinsurancedata':'Datos del Seguro del Vehículo',
  'ri_vehicleemissiontest':'Prueba de Emisiones Vehiculares',
  'ri_vehicletitle':'Titulo',
  'ri_vehicleplates':'Placas',
  'ri_workexperience':'Experiencia Laboral',
  'ri_userinformation':'Información de Usuario',
  'ri_vehicleinformation':'Información del Vehículo',
  'ri_submit':'Enviar',
  'ri_success':'¡Gracias por tu registro! En breve nos pondremos en contacto con usted.',
}



