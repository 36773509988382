import React,{useState} from 'react';
import { useTranslation, initReactI18next } from "react-i18next";
import './App.css';
import { Button,Modal,Container } from 'react-bootstrap';
import validator from 'validator'
import FormIR from './FormRI'
import FormRD from './FormRD'

const axios = require("axios");

const App = () => {

  const { t, i18n } = useTranslation();

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);


  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [newsletter, setNewsletter] = useState('');

  const [loading, setLoading] = useState(false);
  const [errorContact, setErrorContact] = useState(false);
  const [sentContact, setSentContact] = useState(false);

  const [loadingNL, setLoadingNL] = useState(false);
  const [errorContactNL, setErrorContactNL] = useState(false);
  const [errorEmailNL, setErrorEmailNL] = useState(false);
  const [sentContactNL, setSentContactNL] = useState(false);

  const [showModalRI, setShowModalRI] = useState(false);
  const [showModalRD, setShowModalRD] = useState(false);

  async function sendContact(){
    setSentContact(false);
    setErrorContact(false);
    setLoading(true);

    let text = `Subject: ${subject}
    Name: ${name}
    Email: ${email}
    Message: ${message}`;

    let headers = {headers:{}};

    let body = {
      text:text
    }

    let url = process.env.REACT_APP_BACKEND+'/api/user/contact/';

    try{
      const result = await axios.post(url, body, headers);
      console.log('result',result);
      setLoading(false);
      if(result){
        setSentContact(true);
      }else{
        setErrorContact(true);
      }
    }catch (error) {
      setLoading(false);
      setErrorContact(true);
    }
};

async function setNewLetter(){

  if(!validator.isEmail(newsletter)){
    setErrorEmailNL(true);
    return;
  }

  setErrorEmailNL(false);
  setSentContactNL(false);
  setErrorContactNL(false);
  setLoadingNL(true);

  let headers = {headers:{}};

  let body = {
    email:newsletter
  }

  let url = process.env.REACT_APP_BACKEND+'/api/user/newsletter/';

  try{
    const result = await axios.post(url, body, headers);
    console.log('result',result);
    setLoadingNL(false);
    if(result){
      setSentContactNL(true);
    }else{
      setErrorContactNL(true);
    }
  }catch (error) {
    setLoadingNL(false);
    setErrorContactNL(true);
  }
};

  return (
      <>
        {/*<!-- ======= Header ======= -->*/}
        <header id="header" class="fixed-top d-flex align-items-center">
          <div class="container d-flex align-items-center">

            <div class="logo me-auto">
              <h1><a href="index.html">{t('elite_online')}</a></h1>
            </div>

            <nav id="navbar" class="navbar order-last order-lg-0">
              <ul>
                <li><a class="nav-link scrollto active" href="#hero">{t('home')}</a></li>
                <li><a class="nav-link scrollto" href="#about">{t('about_us')}</a></li>
                <li><a class="nav-link scrollto" href="#services">{t('our_services')}</a></li>
                <li><a class="nav-link scrollto" href="#testimonials">{t('testimonial')}</a></li>
                <li><a class="nav-link scrollto" href="#contact">{t('contact')}</a></li>
              </ul>
              <i class="bi bi-list mobile-nav-toggle"></i>
            </nav>{/*<!-- .navbar -->*/}

            <div class="header-social-links d-flex align-items-center">
              <a target="_blank" href="https://www.facebook.com/Elite-Online-110129264733057" class="facebook"><i class="bi bi-facebook"></i></a>
              <a target="_blank" href="https://www.instagram.com/eliteonlineaz/" class="instagram"><i class="bi bi-instagram"></i></a>
              <a target="_blank" href="https://g.page/r/CVi4V3JQU_XzEAE" class="google"><i class="bi bi-google"></i></a>
            </div>
            <div class="header-social-links d-flex align-items-center">
              <a class="" style={{cursor:'pointer'}} onClick={()=>{
                i18n.changeLanguage('en');
              }} >English</a><a style={{marginLeft:'10px'}}  class="" style={{cursor:'pointer'}} onClick={()=>{
                i18n.changeLanguage('es');
              }}>Spanish</a>
            </div>
          </div>
        </header>{/*<!-- End Header -->*/}

        {/*<!-- ======= Hero Section ======= -->*/}
        <section id="hero" class="d-flex flex-column justify-content-center align-items-left">
          <div class="containerhome" style={{}}>
            <img src="assets/img/logotelefono.png" class="img-home align-middle" alt="" style={{'width':'101%',marginTop:'20%'}} />
          </div>
        </section>{/*<!-- End Hero -->*/}

        <main id="main">

          {/*<!-- ======= What We Do Section ======= -->*/}
          <section id="what-we-do" class="what-we-do">
            <div class="container">

              <div class="section-title">
                <h2>{t('text1')}</h2>
                <p>{t('text2')}</p>
              </div>
            </div>
          </section>{/*<!-- End What We Do Section -->*/}

          {/*<!-- ======= About Section ======= -->*/}
          <section id="about" class="about">
            <div class="container">
              <div class="section-title">
                <h2>{t('text3')}</h2>
              </div>
              <div class="row">
                <div class="col-lg-6" style={{padding:'0px'}}>
                  <img src="assets/img/IMG_8702.JPG" class="img-fluid" alt="" />
                </div>
                <div class="col-lg-6 p-lg-5 p-md-5 p-sm-5" style={{backgroundColor:'#b86019',color:'white', /*paddingTop:'80px !important',padding:'0px 160px'*/}}>
                  <h3 style={{/*marginTop:'100px'*/}} class="text-uppercase mt-lg-5" >{t('text3')}</h3>
                  <p style={{/*marginTop:'35px',*/fontSize:'13px'}} class="mt-lg-3" >{t('text4')}</p>
                </div>
              </div>

            </div>
          </section>{/*<!-- End About Section -->*/}

          {/*<!-- ======= Services Section ======= -->*/}
          <section id="services" class="services section-bg">
            <div class="container">
              <div class="section-title">
                <h2>{t('text5')}</h2>
                <p>{t('text6')}</p>
              </div>
              <div class="row" style={{color:'white'}}>
                <div class="col-md-4">
                  <div class="card" style={{backgroundColor:'#000000'}} >
                    <img class="card-img-top" src="assets/img/IMG_7800.jpg" alt="Card image cap" style={{height:'300px'}} />
                    <div class="card-body">
                      <h5 class="card-title text-uppercase "><a class="linksservice" target="_blank" >{t('text7')}</a></h5>
                      <Button variant="primary" onClick={()=>{setShowModal1(true)}}>{t('text45')}</Button>
                      <Button class="btn btn-primary" style={{float:'right'}} onClick={()=>setShowModalRI(true)} >{t('text63')}</Button>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="card" style={{backgroundColor:'#b86019'}} >
                    <img class="card-img-top" src="assets/img/elitecarro.jpg" alt="Card image cap" style={{height:'300px'}}  />
                    <div class="card-body">
                      <h5 class="card-title text-uppercase "><a class="linksservice" target="_blank" >{t('text14')}</a></h5>
                      <Button variant="primary" onClick={()=>{setShowModal2(true)}}>{t('text45')}</Button>
                      <Button class="btn btn-primary" style={{float:'right'}} onClick={()=>setShowModalRD(true)} >{t('text63')}</Button>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="card" style={{backgroundColor:'#f09523'}} >
                    <img class="card-img-top" src="assets/img/IMG_7650_1.jpg" alt="Card image cap" style={{height:'300px'}}  />
                    <div class="card-body">
                      <h5 class="card-title text-uppercase">{t('text26')}</h5>
                      <Button variant="primary" onClick={()=>{setShowModal3(true)}}>{t('text45')}</Button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>{/*<!-- End Services Section -->*/}

          {/*<!-- ======= Testimonials Section ======= -->*/}
          

          <section id="testimonials" class="testimonials section-bg" style={{padding:'0px'}}>
            <div class="container">

              <div class="section-title">
                <h2>Testimonials</h2>
                <p>{t('text32')}</p>
              </div>

              <div class="testimonials-slider swiper-container" data-aos="fade-up" data-aos-delay="100">
                <div class="swiper-wrapper">

                  <div class="swiper-slide">
                    <div class="testimonial-item">
                      <p>
                        <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                        {t('text34')}
                        <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                      {/*<img src="assets/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="" />*/}
                      <h3>{t('text33')}</h3>
                    </div>
                  </div>

                  <div class="swiper-slide">
                    <div class="testimonial-item">
                      <p>
                        <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                        {t('text36')}
                        <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                      {/*<img src="assets/img/testimonials/testimonials-2.jpg" class="testimonial-img" alt="" />*/}
                      <h3>{t('text35')}</h3>
                    </div>
                  </div>

                  <div class="swiper-slide">
                    <div class="testimonial-item">
                      <p>
                        <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                        {t('text38')}
                        <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                      {/*<img src="assets/img/testimonials/testimonials-3.jpg" class="testimonial-img" alt="" />*/}
                      <h3>{t('text37')}</h3>
                    </div>
                  </div>

                  <div class="swiper-slide">
                    <div class="testimonial-item">
                      <p>
                        <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                        {t('text40')}
                        <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                      {/*<img src="assets/img/testimonials/testimonials-4.jpg" class="testimonial-img" alt="" />*/}
                      <h3>{t('text39')}</h3>
                    </div>
                  </div>

                  <div class="swiper-slide">
                    <div class="testimonial-item">
                      <p>
                        <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                        {t('text42')}
                        <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                      </p>
                      {/*<img src="assets/img/testimonials/testimonials-5.jpg" class="testimonial-img" alt="" />*/}
                      <h3>{t('text41')}</h3>
                    </div>
                  </div>
                </div>
                <div class="swiper-pagination"></div>
              </div>

            </div>
          </section>
          {/*<!-- End Testimonials Section -->*/}
          {/*<!-- ======= About Section ======= -->*/}
          <section id="contact" class="contact section-bg">
            <div class="container">
              <div class="section-title">
                <h2>{t('text29')}</h2>
                <p>{t('text30')}</p>
              </div>
              <div class="row">
                <div class="col-lg-6 pt-4 pt-lg-0" style={{}}>
                  <h2 style={{marginTop:'0px',color:'#b86019',fontWeight:'bold'}} class="text-uppercase text-center" >{t('text31')}</h2>
                  <form action="#" method="post" role="form" class="php-email-form" onSubmit={()=>{sendContact();}}>
                    <div class="row">
                      <div class="col-md-6 form-group">
                        <input type="text" name="name" class="form-control" id="name" placeholder={t('text51')} required value={name} onChange={(event)=>{setName(event.target.value);}} />
                      </div>
                      <div class="col-md-6 form-group mt-3 mt-md-0">
                        <input type="email" class="form-control" name="email" id="email" placeholder={t('text52')} required value={email} onChange={(event)=>{setEmail(event.target.value);}}/>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <input type="text" class="form-control" name="subject" id="subject" placeholder={t('text53')} required value={subject} onChange={(event)=>{setSubject(event.target.value);}}/>
                    </div>
                    <div class="form-group mt-3">
                      <textarea class="form-control" name="message" rows="5" placeholder={t('text54')} required onChange={(event)=>{setMessage(event.target.value);}}>{message}</textarea>
                    </div>
                    <div class="my-3">
                      { loading && <div class="loading">{t('text55')}</div>}
                      { errorContact && <div class="error-message">{t('text59')}</div>}
                      { sentContact && <div class="sent-message">{t('text56')}</div>}
                    </div>
                    <div class="text-center"><button type="submit" class="btn btn-secondary" >{t('text46')}</button></div>
                  </form>                </div>
                <div class="col-lg-6" style={{padding:'0px'}}>
                  <img src="assets/img/IMG_7700.jpg" class="img-fluid" alt="" />
                </div>
              </div>

            </div>
          </section>{/*<!-- End About Section -->*/}

        </main>{/*<!-- End #main -->*/}

        {/*<!-- ======= Footer ======= -->*/}
        <footer id="footer" >
          
          <div class="footer-top" style={{}} >
            <div class="container">
              <div class="row">

                <div class="col-lg-3 col-md-6 footer-contact">
                  <h3>{t('elite_online')}</h3>
                  <p>
                    2040 W Buckeye Rd<br/>
                    Phoenix, Az. 85009<br/>
                    United States <br/><br/>
                    <strong>Phone:</strong> 480-222-4422<br/>
                    <strong>Email:</strong> info@eliteonlinellc.com<br/>
                  </p>
                </div>

                <div class="col-lg-2 col-md-6 footer-links">
                  <h4>{t('text47')}</h4>
                  <ul>
                    <li><i class="bx bx-chevron-right"></i> <a href="/">{t('home')}</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#about">{t('about_us')}</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#services">{t('our_services')}</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#testimonials">{t('testimonial')}</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a href="#contact">{t('contact')}</a></li>
                  </ul>
                </div>

                <div class="col-lg-3 col-md-6 footer-links">
                  <h4>{t('text48')}</h4>
                  <ul>
                    <li><i class="bx bx-chevron-right"></i> <a onClick={()=>{setShowModal1(true)}} href="#" class="text-capitalize" >{t('text7')}</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a onClick={()=>{setShowModal2(true)}} href="#" class="text-capitalize" >{t('text14')}</a></li>
                    <li><i class="bx bx-chevron-right"></i> <a onClick={()=>{setShowModal3(true)}} href="#" class="text-capitalize" >{t('text26')}</a></li>
                  </ul>
                </div>

                <div class="col-lg-4 col-md-6 footer-newsletter">
                  <h4>{t('text49')}</h4>
                  {/*<p>{t('text57')}</p>*/}
                  <div class={'form'} >
                    <input type="email" name="email" value={newsletter} onChange={(event)=>{
                      setNewsletter(event.target.value);
                    }} />
                    <input type="submit" onClick={()=>{
                      setNewLetter();
                    }} value={t('text64')} />

                  </div>
                  { loadingNL && <div class="loading">{t('text55')}</div>}
                  { errorContactNL && <div class="error-message">{t('text60')}</div>}
                  { errorEmailNL && <div class="error-message">{t('text62')}</div>}
                  { sentContactNL && <div class="sent-message">{t('text56')}</div>}
                </div>

              </div>
            </div>
          </div>

          <div class="container d-md-flex py-4">

            <div class="me-md-auto text-center text-md-start">
              <div class="copyright">
                &copy; Copyright <strong><span>{t('elite_online')} LLC.</span></strong> All Rights Reserved
              </div>
              <div class="credits">
                {t('text58')}
              </div>
            </div>
            <div class="social-links text-center text-md-right pt-3 pt-md-0" style={{marginRight:'100px'}}>
              <a target="_blank" href="https://www.facebook.com/Elite-Online-110129264733057" class="facebook"><i class="bx bxl-facebook"></i></a>
              <a target="_blank" href="https://www.instagram.com/eliteonlineaz/" class="instagram"><i class="bx bxl-instagram"></i></a>
              <a target="_blank" href="https://g.page/r/CVi4V3JQU_XzEAE" class="goocle"><i class="bi bi-google"></i></a>
            </div>
          </div>
        </footer>{/*<!-- End Footer -->*/}

        <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
        <div class="" style={{position:'fixed',bottom:'15px',right:'0px',overflowY: 'auto'}}>
            {/*<a  class="btn btn-primary" href="#" role="button" style={{fontSize:'21px'}} >{t('text44')} <i class="bi bi-phone"></i></a>*/}
            <a class="btn btn-primary" href="#" role="button" style={{fontSize:'21px',maxWidth:'400px',zIndex:'9999999'}} >{t('text57')}</a>
          </div>
        <Modal show={showModal1} onHide={()=>{setShowModal1(false)}} backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{t('text7')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                  <p class="card-text">{t('text8')}</p>
                  <p class="card-text">{t('text9')}</p>
                  <ul class="list-groupx">
                    <li class="list-group-itemx">{t('text10')}</li>
                    <li class="list-group-itemx">{t('text11')}</li>
                    <li class="list-group-itemx">{t('text12')}</li>
                    <li class="list-group-itemx">{t('text13')}</li>
                  </ul>
                  <Button class="btn btn-primary" style={{float:'right'}} onClick={()=>setShowModalRI(true)} >{t('text63')}</Button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{setShowModal1(false)}} >{t('text43')}</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showModal2} onHide={()=>{setShowModal2(false)}} backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{t('text14')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                  <p class="card-text">{t('text15')}</p>
                  <p class="card-text">{t('text16')}</p>
                  <ul class="list-groupx">
                    <li class="list-group-itemx">{t('text17')}</li>
                    <li class="list-group-itemx">{t('text18')}</li>
                    <li class="list-group-itemx">{t('text19')}</li>
                    <li class="list-group-itemx">{t('text20')}</li>
                    <li class="list-group-itemx">{t('text21')}</li>
                    <li class="list-group-itemx">{t('text22')}</li>
                    <li class="list-group-itemx">{t('text23')}</li>
                    <li class="list-group-itemx">{t('text24')}</li>
                    <li class="list-group-itemx">{t('text25')}</li>
                  </ul>
                  <Button class="btn btn-primary" style={{float:'right'}} onClick={()=>setShowModalRD(true)} >{t('text63')}</Button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{setShowModal2(false)}}>{t('text43')}</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showModal3} onHide={()=>{setShowModal3(false)}} backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{t('text26')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p class="card-text">{t('text27')}</p>
              <p class="card-text">{t('text28')}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{setShowModal3(false)}}>{t('text43')}</Button>
          </Modal.Footer>
        </Modal>   


        <Modal size="lg" show={showModalRI} onHide={()=>{setShowModalRI(false)}} backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{t('text65')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <FormIR t={t} setShowModalRI={setShowModalRI} />
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{setShowModalRI(false)}}>{t('text43')}</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showModalRD} onHide={()=>{setShowModalRD(false)}} backdrop="static" keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{t('text66')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className="show-grid">
              <FormRD t={t} setShowModalRD={setShowModalRD} />
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{setShowModalRD(false)}}>{t('text43')}</Button>
          </Modal.Footer>
        </Modal>   


  </>);
  };

export default App;