import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {l_en,l_es} from './translation.js';


console.log(l_en);


const fallbackLng = ['en'];
const availableLanguages = ['en','es'];

i18n
  .use(Backend) // load translations using http (default public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    lng: "en", 
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
        useSuspense: false,
    },
    resources: {
      'en': {
        translation: l_en
      },
      'es': {
        translation: l_es
      }
    }
  }, (err, t) => {
    if (err) return console.error(err)
  });

/*export default i18n;*/