import React,{useState,useEffect} from 'react';
import * as yup from "yup";
import { Form, Col,Row,InputGroup,Button,Alert } from 'react-bootstrap';
import { Person,House,Envelope,Phone,Search,ArrowRight } from 'react-bootstrap-icons';
import { Formik, Field } from "formik";
import swal from 'sweetalert';

const axios = require("axios");

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  cityId: yup.number().required().positive().integer(),
  zipCode: yup.number().required().positive().integer(),
  email: yup.string().email().required(),
  number: yup.number().required().positive().integer(),
  street: yup.string().required(),
  nsew: yup.string().required(),
  phone: yup.number().required().positive().integer(),
  howfindus: yup.string().required(),
});

const FormRD = (props) => {

  const [cityValues, setCityValues] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    console.log('getCities');
    getCities();
  },[]);

  useEffect(() => {
    setLoadingCities(false);
  },[cityValues]);

  async function getCities(){
    setLoadingCities(true);

    let headers = {headers:{}};
    let body = {}
    let url = process.env.REACT_APP_BACKEND+'/api/catalog/cities/8';

    try{
      const result = await axios.get(url, headers);
      console.log('result',result);
      if(result.data){
        setCityValues(result.data);
      }
    }catch (error) {
      setLoadingCities(false);
    }
  };

  async function send(values, actions){
    setSending(true);

    let headers = {headers:{}};

    let url = process.env.REACT_APP_BACKEND+'/api/driver/';

    try{
      const result = await axios.post(url, values, headers);
      console.log('result',result);
      setSending(false);
      if(result.data){

        swal(props.t('ri_success'));
        props.setShowModalRD(false);
        setSuccess(true);
      }
    }catch (error) {
      console.log('error',error.response.data);
      setError(error.response.data.message);
      setSending(false);

    }
};

  return (
    <Formik
      validationSchema={schema}
      onSubmit={send}
      initialValues={{
        firstName: '',
        lastName: '',
        cityId: 55,
        zipCode: '',
        email: '',
        number: '',
        street: '',
        nsew: 'N',
        phone: '',
        howfindus: '',
        type:1
      }}
    >
      {({handleSubmit,handleChange,handleBlur,values,touched,isValid,errors,}) => (
        <Form noValidate onSubmit={handleSubmit}>
          {/*<p>{JSON.stringify(values)}</p>*/}
          <Row>
            <h5>{props.t('ri_userinformation')}</h5>
            <Col xs={12} md={12} >
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationFormik1">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend"><Person /></InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder={props.t('ri_first_name')}
                      aria-describedby="inputGroupPrepend"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isInvalid={!!errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationFormik2">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend"><Person /></InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder={props.t('ri_last_name')}
                      aria-describedby="inputGroupPrepend"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isInvalid={!!errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationFormik3">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend"><House /></InputGroup.Text>
                    <Form.Select 
                      aria-label="Default select example"
                      placeholder={props.t('ri_city')}
                      aria-describedby="inputGroupPrepend"
                      name="cityId"
                      value={values.cityId}
                      onChange={handleChange}
                      isInvalid={!!errors.cityId}
                    >
                      {cityValues && cityValues.map((item)=>{
                        return <option value={item.cityId} selected={(item.cityId==55?true:false)} >{item.city}</option>
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.cityId}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationFormik4">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend"><House /></InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder={props.t('ri_street')}
                      aria-describedby="inputGroupPrepend"
                      name="street"
                      value={values.street}
                      onChange={handleChange}
                      isInvalid={!!errors.street}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.street}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationFormik5">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend"><House /></InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder={props.t('ri_number')}
                      aria-describedby="inputGroupPrepend"
                      name="number"
                      value={values.number}
                      onChange={handleChange}
                      isInvalid={!!errors.number}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.number}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationFormik6">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend"><House /></InputGroup.Text>
                    <Form.Select 
                      aria-label="Default select example"
                      placeholder={props.t('ri_nsew')}
                      aria-describedby="inputGroupPrepend"
                      name="nsew"
                      value={values.nsew}
                      onChange={handleChange}
                      isInvalid={!!errors.nsew}
                    >
                      <option value="N" >North</option>
                      <option value="S" >South</option>
                      <option value="W" >West</option>
                      <option value="E" >East</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.nsew}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationFormik7">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend"><House /></InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder={props.t('ri_zipcode')}
                      aria-describedby="inputGroupPrepend"
                      name="zipCode"
                      value={values.zipCode}
                      onChange={handleChange}
                      isInvalid={!!errors.zipCode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.zipCode}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationFormik8">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend"><Envelope /></InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder={props.t('ri_email')}
                      aria-describedby="inputGroupPrepend"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationFormik9">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend"><Phone /></InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder={props.t('ri_phone')}
                      aria-describedby="inputGroupPrepend"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationFormik10">
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend"><Search /></InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder={props.t('ri_howfindus')}
                      aria-describedby="inputGroupPrepend"
                      name="howfindus"
                      value={values.howfindus}
                      onChange={handleChange}
                      isInvalid={!!errors.howfindus}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.howfindus}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
            </Col>

            {/*<Form.Group className="mb-3">
              <Form.Check
                required
                name="terms"
                label="Agree to terms and conditions"
                onChange={handleChange}
                isInvalid={!!errors.terms}
                feedback={errors.terms}
                id="validationFormik0"
              />
                    </Form.Group>*/}
            {error && <Alert variant="danger" >{error}</Alert>}
            {/*success && <Alert variant="success" >{props.t('ri_success')}</Alert>*/}
            
            <Button type="submit">{props.t('ri_submit')}</Button>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export default FormRD;
